const isLeaf = (data, prop) => {
    return !(Array.isArray(data[prop]) && data[prop].length > 0);
};

export const renderNode = (h, data, context, onlychild) => {
    const { props } = context;
    const cls = ['org-tree-node'];
    const childNodes = [];
    const children = data[props.props.children];

    if (isLeaf(data, props.props.children)) {
        cls.push('is-leaf');
    } else if (props.collapsable && !data[props.props.expand]) {
        cls.push('collapsed');
    }

    childNodes.push(renderLabel(h, data, context));

    if (!props.collapsable || data[props.props.expand]) {
        childNodes.push(renderChildren(h, children, context, onlychild));
    }

    return h(
        'div',
        {
            domProps: {
                className: cls.join(' ')
            }
        },
        childNodes
    );
};

export const renderBtn = (h, data, context) => {
    const { props } = context;
    const expandHandler = context.listeners['on-expand'];

    const cls = ['org-tree-node-btn'];

    if (data[props.props.expand]) {
        cls.push('expanded');
    }

    return h('span', {
        domProps: {
            className: cls.join(' ')
        },
        on: {
            click: (e) => {
                e.stopPropagation();
                expandHandler && expandHandler(data);
            }
        }
    });
};

export const renderLabel = (h, data, context) => {
    const { props } = context;
    const label = data[props.props.label];
    const renderContent = props.renderContent;
    const clickHandler = context.listeners['on-node-click'];
    const removeAssocHandler = context.listeners['on-assoc-remove-click'];
    const value = typeof data.value !== 'undefined' && data.value !== '' ? data.value : '';

    const childNodes = [];
    if (typeof renderContent === 'function') {
        const vnode = renderContent(h, data);
        vnode && childNodes.push(vnode);
    } else {
        childNodes.push(label);
    }
    if (props.collapsable && !isLeaf(data, props.props.children)) {
        childNodes.push(renderBtn(h, data, context));
    }

    if (value !== '') {
        childNodes.push(
            h(
                'div',
                {
                    domProps: {
                        className: 'inner-value'
                    }
                },
                value
            )
        );
    }

    const cls = ['org-tree-node-label-inner'];
    if (
        typeof data.children === 'undefined' ||
        data.children.length < 1 ||
        (typeof data.childrenArray !== 'undefined' && data.childrenArray)
    )
        cls.push('clickable');
    if (typeof data.isArray !== 'undefined' && data.isArray === true) cls.push('hidden');
    if (typeof data.isProductContainer !== 'undefined' && data.isProductContainer === true)
        cls.push('product-container');
    let { labelWidth, labelClassName } = props;
    if (typeof labelWidth === 'number') {
        labelWidth += 'px';
    }
    if (typeof labelClassName === 'function') {
        labelClassName = labelClassName(data);
    }
    labelClassName && cls.push(labelClassName);

    if (data.assoc !== '') {
        const assocText = h(
            'span',
            {
                domProps: {
                    className: 'assoc-val-text'
                }
            },
            data.assoc.label
        );
        const assocBtnRemove = h('i', {
            domProps: {
                className: 'removeAssocBtn el-icon-delete'
            },
            on: {
                click: (e) => removeAssocHandler && removeAssocHandler(e, data)
            }
        });
        const assocParts = [assocText, assocBtnRemove];
        return h(
            'div',
            {
                domProps: {
                    className: 'org-tree-node-label has-associated-value'
                }
            },
            [
                h(
                    'div',
                    {
                        domProps: {
                            className: cls.join(' ')
                        },
                        style: {
                            width: labelWidth
                        },
                        on: {
                            click: (e) => clickHandler && clickHandler(e, data)
                        }
                    },
                    childNodes
                ),
                h(
                    'div',
                    {
                        domProps: {
                            className: 'assoced-value'
                        }
                    },
                    assocParts
                )
            ]
        );
    } else {
        return h(
            'div',
            {
                domProps: {
                    className: 'org-tree-node-label'
                }
            },
            [
                h(
                    'div',
                    {
                        domProps: {
                            className: cls.join(' ')
                        },
                        style: {
                            width: labelWidth
                        },
                        on: {
                            click: (e) => clickHandler && clickHandler(e, data)
                        }
                    },
                    childNodes
                )
            ]
        );
    }
};

export const renderChildren = (h, list, context, onlychild) => {
    if (Array.isArray(list) && list.length) {
        const children = list.map((item, row) => {
            if (onlychild && row !== onlychild) {
                return null;
            }
            return renderNode(h, item, context, null);
        });

        return h(
            'div',
            {
                domProps: {
                    className: 'org-tree-node-children'
                }
            },
            children
        );
    }
    return '';
};

export const render = (h, context) => {
    const { props } = context;

    return renderNode(h, props.data, context);
};

export default render;
