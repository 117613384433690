<template>
	<div class="org-tree-container">
		<div class="org-tree" :class="{horizontal, collapsable}">
			<org-tree-node v-for="(d, index) in data" :key="index" :data="d" :props="props" :horizontal="horizontal" :onlyRow="onlyRow" :label-width="labelWidth" :collapsable="collapsable"
			 :render-content="renderContent" :label-class-name="labelClassName" @on-expand="$emit('on-expand', $event)"
			 @on-assoc-remove-click="(e, data) => {$emit('on-assoc-remove-click', e, data)}"
			 @on-node-click="(e, data) => {if(typeof data.children === 'undefined' || data.children.length < 1 || (typeof data.childrenArray !== 'undefined' && data.childrenArray)) $emit('on-node-click', e, data)}"></org-tree-node>
		</div>
	</div>
</template>

<script>
	import render from './node.js';
	export default {
		name: 'Tree',
		components: {
			OrgTreeNode: {
				render,
				functional: true
			}
		},
		props: {
			data: {
				type: Array,
				required: true
			},
			props: {
				type: Object,
				default: () => ({
					label: 'label',
					expand: 'expand',
					children: 'children'
				})
			},

			horizontal: Boolean,

			onlyRow: Number,
			firstLevelRoot: Boolean,
			collapsable: Boolean,
			renderContent: Function,
			labelWidth: [String, Number],
			labelClassName: [Function, String]
		}
	};
</script>

<style lang="scss">
	@import '../../styles/modules/tree.scss';
</style>